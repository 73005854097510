﻿.rtHelpMessage {
    display: none;
    color: #000000;
    padding: 1rem 0;

    a {
        color: #000000 !important;
    }
}

.rtHelpButton_Common {
    display: block;
    overflow: hidden;
    background: url("/resource/help.svg");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    float: right;
    margin: 0;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0;
    top: 0;
}
