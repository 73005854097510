﻿.more-text,
.more-text div {
    display: inline-block;
    padding: 0;
    vertical-align: top;
}

.more-text {
    .more-text__max {
        display: none;
    }

    .hidden {
        display: none;
    }

    .show {
        display: inline-block;
    }

    a {
        color: #0064D6;

        &:hover {
            color: #0064D6;
            text-decoration: underline;
        }
    }
}
