﻿.rtTACView, .rtRECView {
    min-height: 21px;
}

.rtTACView {
    font-size: 1.4rem;
}

.rtRECView iframe {
    width: 100%;
}

.rtTAC, .rtREC {
    border: 0 none;
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
}

.rtTACEdit, .rtRECEdit {
    clear: both;
}

.rtTACView, .rtRECView iframe {
    background-color: #ffffff;
    border: 2px solid #E0E8EC;
    max-height: 200px;
    overflow-x: auto;
    min-height: 34px;
    border-radius: 4px;
    transition: 0.3s;
    margin: 0;

    &:hover {
        border-color: #BEBEBE;
    }
}

.rtTACView, .rtTACEdit .riTextBox {
    padding: 8px 10px 8px 33px;

    @media only screen and (min-width: 768px) {
        padding: 6px 8px;
    }
}

.rtTACEditbtn, .rtRECEditbtn {
    background: url("/resource/edit.svg") center center no-repeat;
    background-size: 18px;
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 0;
    height: 38px;
    width: 20px;

    @media only screen and (min-width: 768px) {
        left: -24px;
    }

    &:hover {
        background-image: url("/resource/edit-hover.svg");
    }
}

.rtTACViewbtn, .rtRECViewbtn {
    display: none;
    background: url("/resource/search.svg") center center no-repeat;
    background-size: 18px;
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 0;
    height: 38px;
    width: 20px;

    @media only screen and (min-width: 768px) {
        left: -24px;
    }

    &:hover {
        background-image: url("/resource/search-hover.svg");
    }
}
