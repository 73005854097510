﻿.error-componant {
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 12px;
    width: 100%;
    text-align: left;
    padding: 8px;
    @include font-size(14);

    &__image {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        vertical-align: top;
    }

    &__text {
        display: inline-block;
        vertical-align: middle;
    }

    ul {
        list-style: disc;
        margin-left: 2rem;
        margin-bottom: 0;
    }

    &__none {
        background: rgb(248, 248, 248);
        border: 2px solid rgb(164, 164, 164);
        color: $color-black;

        .image {
            width: 0;
            height: 0;
        }
    }

    &__information {
        border: 2px solid #0000f7;
        background-color: rgba(0, 0, 255, 0.06);
        color: #0000f7;

        .image {
            background-image: url('/resource/icon-information.svg"')
        }

        .error-componant__text {
            width: calc(100% - 40px);
        }
    }

    &__warning {
        border: 2px solid #fb9700;
        background-color: rgba(255, 255, 0, 0.06);
        color: #a56a12;

        .image {
            background-image: url('/resource/icon-warning.svg"');
            height: 27px;
        }

        .error-componant__text {
            width: calc(100% - 40px);
        }
    }

    &__critical {
        border: 2px solid $color-red;
        color: $color-red;
        background-color: rgba(255, 0, 0, 0.06);

        .image {
            background-image: url('/resource/icon-critical.svg"')
        }

        .error-componant__text {
            width: calc(100% - 40px);
        }
    }
}

.main-content-body-error {
    font-size: 13px;
    width: 100%;
    display: inline-block;
    padding: 8px 14px;
    margin-top: 20px;
    border-radius: 5px;

    &.critical {
        background-color: #fdf2f2;
        border: 2px solid #e77878;
        color: #D20001;
    }

    &.information {
        border: 2px solid #0000f7;
        background-color: rgba(0, 0, 255, 0.06);
        color: $color-black;
    }

    &.warning {
        border: 2px solid #e9e900;
        background-color: rgba(255, 255, 0, 0.06);
        color: $color-black;
    }

    &__image {
        display: inline-block;
        max-width: 46px;
        width: 100%;
        vertical-align: middle;
    }

    &__text {
        display: inline-block;
        width: calc(100% - 60px);
        vertical-align: middle;
        padding: 9px 0 9px 10px;
    }

    &__title {
        font-size: 16px;
    }

    &__attempt {
        float: right;
    }

    &__desc {
        width: 100%;
        display: inline-block;
        font-style: italic;
        padding-top: 5px;
    }
}

.day-error {
    border: 1px solid red;
}
