﻿@keyframes c-popout-message-panel-palette {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
    /*    100% {
        opacity: 0.3;
    }*/
}

.c-popout-message-panel {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    z-index: 10000;

    &-info {
        #{$root}__content {
            border: blue solid;
            background-color: lavender;
        }

        #{$root}__ctrl-button {
            background: linear-gradient(to bottom,rgba(0,0,255,1) 100%, rgba(0,0,255,1) 0%);
        }
    }

    &--hidden {
        #{$root}__ctrl-button {
            opacity: 0.3;
            animation-name: c-popout-message-panel-palette;
            animation-delay: 0s;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        #{$root}__view {
            display: none;
        }
    }

    &__view {
        overflow: scroll;
    }

    &__view-close {
        position: fixed;
        top: 10px;
        right: 26px;
        text-align: left;
        width: 60px;
        height: 25px;
        z-index: 9988;
        background: none;
        padding: 0;
        border: none;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            right: 0;
            background-color: $color-black;
            width: 20px;
            height: 6px;
            border-radius: 2px;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }

    &__content {
        position: relative;
        background-color: #ff0;
        padding: 10px;
        border-bottom: 1px solid #aaaaaa;
        overflow: hidden;
        border: red solid;
        min-height: 300px;
    }

    &__ctrl {
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 9986;
    }

    &__ctrl-button {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background: linear-gradient(to bottom,rgba(255,0,0,1) 100%, rgba(219,0,0,1) 0%);
        width: 120px;
        height: 14px;
        text-indent: -9999px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        box-shadow: 0 0 3px rgba($color-black, 0.8);
        transition: all 0.2s ease-in-out;
        border: 0;

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 50%;
            margin-left: -21px;
            width: 42px;
            height: 4px;
            background-color: #eeeeee;
            transition: all 0.2s ease-in-out;
            border-radius: 5px;
            box-shadow: 0 0 3px rgba($color-black, 0.4);
            opacity: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 50%;
            margin-left: -10px;
            width: 20px;
            height: 4px;
            background-color: #eeeeee;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 0 3px rgba($color-black, 0.4);
            border-radius: 5px;
            opacity: 0;
        }

        &:hover {
            height: 30px;
            opacity: 1;
            animation-name: none;

            &:before {
                top: 10px;
                opacity: 1;
            }

            &:after {
                top: 18px;
                opacity: 1;
            }
        }
    }
}
