.c-error-summary {

    &__title {
        @include font-size(20);
        margin-bottom: 0.5rem;
        color: red;
    }

    &__list {
        li {
            color: red;
        }
    }
}

#buttons_ValidationSummary > div > ul {
    padding-top: 6px;
}
