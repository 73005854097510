﻿.click-to-copy-me {
    $root: &;
    cursor: copy;
    position: relative;

    &:hover {
        background-color: palegreen;

        #{$root}-button {
            display: block;
        }
    }

    &-button {
        background: #f1f1f1 50% 50% no-repeat;
        border: 1px solid #858585;
        border-radius: 0.25rem;
        cursor: pointer;
        display: none;
        height: 25px;
        width: 25px;
        padding: 6px 12px;
        position: absolute;
        right: -26px;
        top: -3px;
        vertical-align: middle;
        z-index: 9000;

        span {
            display: none;
        }

        &:after {
            background-color: #4e4e4e;
            content: "";
            -webkit-mask-image: url(/resource/clippy.svg);
            mask-image: url(/resource/clippy.svg);
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-size: contain;
            mask-size: contain;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 16px;
            height: 16px;
        }
    }
}
