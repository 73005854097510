﻿.skip-links {
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow: visible;
    width: 100%;
    z-index: 9999;
    margin: 0 !important;
    padding: 0;

    &__list-item {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: inline-block;

        &:not(:focus):not(:active) {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
        }
    }
}
