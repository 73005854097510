/*
    Settings: COLORS

    The color palette is where colors across the site are set.
    Here are the rules:
        -   Only reference specific hexadecimal colors within the Color Palette area of this partial.
        -   Color names are based on hexadecimal color here: http://chir.ag/projects/name-that-color/
        -   These variables will be used in various places
*/

$color-black-dark: #000000;
$color-black: #1b1d1e;
$color-white: #ffffff;
$color-dark-grey: #252728;
$color-grey: #BEBEBE;
$color-greys: #bdbebe;
$color-light-grey: #dde5e8;
$color-lighter-grey: #f0f5f7;
$color-lightest-grey: #f7fafb;
$color-pale-grey: #f5f5f5;
$color-grey-border: #E0E8EC;
$color-grey-selected-border: #D7E1E4;
$color-grey-selected-line: #a9a9a9;
$color-faintblue: #F0F5F8;
$color-light-blue: #597796;
$color-gloomy-white: #f6fafb;

$color-red: #d10000;
$color-blue: #005dd1;
$color-green: #34a528;
$color-grass-green: #3CC480;
$color-link-blue: #0064D6;
$color-yellow: #ffff00;
$color-orange: #ff9900;

$color-box-shadow: #CCCCCC;



// States

$color-delete-red: #D20001;
$color-recyclebin: #3CC480;
$color-shredder: #E5B721;

$color-published: #34a528;
$color-unpublished: #E4E8E9;
$color-draft: #E5B721;


// Hover button
$color-black-hover: #404040;


$color-information: #0b0b84;
$color-ok: green;
$color-critical: #b60000;
$color-warning: #cc4400;
$color-disabled: grey;
