﻿.c-title-ctrls {
    display: inline;
    padding: 0 1rem;

    ul {
        display: inline;
        width: auto;
        margin-bottom: 0;
    }

    li {
        padding: 0;
        margin: 0;
    }

    &__ctrl {
        display: inline-block;
    }

    svg {
        width: 100%;
    }
}
