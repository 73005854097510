﻿.rt-postback-content {
    $root: &;

    &-overlay {
        display: none;
    }

    &-overlay-message {
        display: none;
    }

    @at-root {
        .postback-lock {

            #{$root}-overlay {
                background-color: rgba(149, 149,149,.5);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 9998;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                min-height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
            }

            #{$root}-overlay-message {
                background-color: white;
                width: 300px;
                height: 125px;
                margin: auto;
                display: block;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border: 2px solid #939191;
            }
        }
    }
}
