﻿.rtUIWarning {
    padding: 9px 6px;
    font-size: 19px;
    display: none;
    position: fixed;
    top: 0px;
    background-color: #ec4b2e;
    width: 100%;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    z-index: 9999;

    @include min($width-tablet) {
        padding: 11px 6px;
        font-size: 19px;
    }
}

.rtUIDialog {
    width: 400px;
    text-align: center;
    font-size: 19px;

    @include min($width-tablet) {
    }
}

.rtSessionLink {
    border-bottom: 2px solid $color-white;
    color: $color-white;

    &:hover {
        border-bottom: 0;
    }

    &:visited, &:hover, &:active {
        text-decoration: none;
    }
}

.rtSessionButton {
    color: #000000;
    font: 12px Verdana, Arial, Helvetica, sans-serif;
    color: #000000;
    border-radius: 5px;
    border: 1px solid #000000;
    height: auto;
    width: auto;
    vertical-align: middle;
    padding: 2px 7px;
    margin: 2px 4px;
    background-color: #e0e0e0;

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;
    }
}


.rtSecurityTokenMessage {
    padding: 0 20px;
    width: 400px;
    font-size: 19px;
}

.rtConcurrentLoginMessage {
    position: fixed;
    top: 0px;
    background-color: #ec4b2e;
    width: 100%;
    color: #FFFFFF;
    text-align: center;
    padding: 2px 0px 0px 5px;
    font-size: 19px;
    z-index: 9999;
}
