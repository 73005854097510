﻿.pass_help, .pass_help {

    span, ul, li {
        font-size: 1.2rem;
        color: red;
    }

    span, ul {
        margin-left: 5px;
    }

    ul {
        list-style: disc;
    }

    li {
        margin-left: 22px;
    }
}

.pass_score {
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    margin: 2px 1px;
    border-color: #000;
    padding: 2px 2px 3px;
}
